
  export const environment = {
    production: true,
    apiUrl: 'https://montijogiorapi.azurewebsites.net/api',
    functionsUrl: 'https://montijogior.azurewebsites.net/api',
    hide6fAppData: true,
    hideNews: false,
    mapId: 'f33257edfefa7f3a',
    showLocationContactListData: true,
    hideLocationContactDetailData: false
  };